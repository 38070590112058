import "./financialAid.css";

import * as Dialog from "@radix-ui/react-dialog";
import Header from "components/Header";
import ModalWrapper from "components/ModalWrapper";
import OutLink from "components/OutLink";
import Paragraph from "components/Paragraph";
import Tooltip from "components/Tooltip";
import React, { useEffect, useRef, useState } from "react";
import { angularize } from "react-in-angularjs";

const FinancialAid = ({ title }: any) => {
  const [firstElement, setFirstElement] = useState<HTMLDivElement>();
  const [lastElement, setLastElement] = useState<HTMLDivElement>();
  const anchorTag = useRef<HTMLAnchorElement>(null);
  const urlHostName = sessionStorage.getItem("urlHostName");
  const urlQAT = "portalqat.umgc.edu";
  const urlSTG = "portalstg.umgc.edu";
  const urlPROD = "students.umgc.edu";
  const portalQAT =
  "https://awscsint-sso.psdev.umgc.edu/psc/csintsso/EMPLOYEE/SA/c/SFA_STUDENT_FL.SFA_SS_START_PG_FL.GBL?GMenu=SFA_STUDENT_FL&GComp=SFA_SS_START_PG_FL&GPage=SFA_SS_START_PG_FL";
  const portalSTG =
    "https://awscsuat-sso.psdev.umgc.edu/psc/csuatsso/EMPLOYEE/SA/c/SFA_STUDENT_FL.SFA_SS_START_PG_FL.GBL?GMenu=SFA_STUDENT_FL&GComp=SFA_SS_START_PG_FL&GPage=SFA_SS_START_PG_FL";
  const portalPROD =
    "https://pshr.ps.umgc.edu/psc/uuccsprd/EMPLOYEE/SA/c/SFA_STUDENT_FL.SFA_SS_START_PG_FL.GBL?GMenu=SFA_STUDENT_FL&GComp=SFA_SS_START_PG_FL&GPage=SFA_SS_START_PG_FL";
  const [showDialog, setShowDialog] = useState(false);
  const [escPressed, setEscPressed] = useState(false);
  const text = "A new tab will open to display this page";
  const focusableElements = "button";
  const handleClick = (e: any = null) => {
    e.preventDefault();
    setShowDialog(true);
    title === "view"
      ? document.getElementById("financialAidLink")?.blur()
      : document.getElementById("aFinList")?.blur();
    document.body.style.overflow = "hidden";
  };
  const close = (e: any) => {
    document.body.style.overflow = "auto";
    title === "view"
      ? document.getElementById("financialAidLink")?.focus()
      : document.getElementById("aFinList")?.focus();
    setShowDialog(false);
  };

  const OK = (e: any, _url: string) => {
    window.open(_url, "_blank", "noreferrer");
    document.body.style.overflow = "auto";
    title === "view"
      ? document.getElementById("financialAidLink")?.focus()
      : document.getElementById("aFinList")?.focus();
    setShowDialog(false);
    e.preventDefault();
  };

  const keydownFunc = (e: any) => {
    const isTabPressed = e.key === "Tab" || e.keyCode === 9;
    const isEscPressed = e.key === "Escape" || e.keyCode === 27;
    if (isEscPressed) {
      setEscPressed(true);
      close(e);
      return false;
    }
    if (!isTabPressed) {
      return;
    }
    if (e.shiftKey) {
      if (document.activeElement === firstElement) {
        lastElement && lastElement.focus();
        e.preventDefault();
      }
    } else {
      if (document.activeElement === lastElement) {
        firstElement && firstElement.focus();
        e.preventDefault();
      }
    }
  };

  useEffect(() => {
    if (showDialog) {
      const modal = document.getElementById("financialAid");
      const focusableContent: any = modal?.querySelectorAll(focusableElements);
      setFirstElement(focusableContent ? focusableContent[0] : null);
      setLastElement(
        focusableContent ? focusableContent[focusableContent.length - 1] : null
      );

      document.addEventListener("keydown", keydownFunc);
      firstElement && firstElement.focus();
      return () => {
        document.removeEventListener("keydown", keydownFunc);
      };
    } else {
      const bodyEle = document.body;
      (bodyEle as any).style = "";
      if (escPressed) {
        title === "view"
          ? document.getElementById("financialAidLink")?.focus()
          : document.getElementById("aFinList")?.focus();
      }
    }
  }, [showDialog, firstElement, lastElement, escPressed]);

  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;

  return (
    <div className={title === "index" ? "fin-list" : ""}>
      {title === "index" && (
        <a
          tabIndex={0}
          id="aFinList"
          href="javascript:void(0)"
          className=" relative pb-[0.75rem] pl-[3.125rem] pr-[0.313rem] pt-[0.75rem] font-normal text-[#a30606]"
          title="view"
          onClick={(e) => handleClick(e)}
          onKeyDown={(e) => {
            e.key == " " ? handleClick() : null;
          }}
        >
          Financial Aid
        </a>
      )}
      {title === "view" && (
        <section
          aria-labelledby="Student Account Center"
          id="du-class-finances-landing-Student-Account-Center"
          className="border-box -webkit-shadow-[5px 5px 5px 0px rgba(0,0,0,0.1)] moz-shadow-[5px_5px_5px_0px_rgba(255,255,0,1)] relative  mb-[1.25rem] mr-[1.75rem] bg-[#ffffff] p-[1.25rem]"
          style={{
            boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.1)",
            width: "95%",
          }}
        >
          <div className="flex-justify-between">
            <div className="text-left">
              <h3
                className="text-[1.5em]"
                style={{
                  fontFamily: "'Roboto', Tahoma, sans-serif",
                  fontWeight: "300",
                  lineHeight: "1.4em",
                }}
              >
                Financial Aid
              </h3>
            </div>
            <br />
            <p className="font-sans text-[0.9735rem] font-normal leading-[1.6em] text-[#3c3c43]">
              Make sure you file your {currentYear}-{nextYear} FAFSA if you
              haven't already, review your financial aid eligibility status,
              check the status of your awards to accept or decline them,
              complete required financial aid To Dos, and manage your Federal
              loans.
            </p>
            <br />
            <a
              id="financialAidFileTheFAFSA"
              href="https://studentaid.gov/h/apply-for-aid/fafsa"
              target="blank"
              className="relative cursor-[url(hand.cur),_pointer] text-[0.9375rem] font-bold text-[#003e6a] hover:underline"
            >
              File the FAFSA
            </a>
            <br />
            <a
              id="financialAidLink"
              href="javascript:void(0)"
              className="relative cursor-[url(hand.cur),_pointer] text-[0.9375rem] font-bold text-[#003e6a] hover:underline"
              onClick={handleClick}
              onKeyDown={(e) => {
                e.key == " " ? handleClick() : null;
              }}
            >
              View Financial Aid
            </a>
            <br />

            <a
              ref={anchorTag}
              id="bookVoucherRequest"
              title="Most UMGC courses do not require you to purchase books or other materials. Check your course syllabi, and if needed, you can request financial aid to cover book costs. "
              href="#/bookVoucher"
              className="relative cursor-[url(hand.cur),_pointer] text-[0.9375rem] font-bold text-[#003e6a] hover:underline"
            >
              <span
                id="bookVoucherRequestPage"
                className="cursor-pointer border-none before:mr-0 before:inline-block after:font-awesome after:text-sm after:font-light after:content-['\f05a']"
              >
                Book Voucher Request&nbsp;
              </span>
              <span className="absolute -left-[230px] -top-[34px] m-4 mx-auto w-[210px] rounded-md bg-du-light-gray p-2 text-[11px] text-sm opacity-0 transition-opacity group-hover:opacity-100">
                Syllabus will be made available 3 weeks prior to class start
                date
              </span>
            </a>
            <br />
            <a
              id="learnMoreFinancialAid"
              target="blank"
              href="https://www.umgc.edu/current-students/finances/financial-aid"
              className="relative cursor-[url(hand.cur),_pointer] text-[0.9375rem] font-bold text-[#003e6a] hover:underline"
            >
              Learn More About Financial Aid
            </a>
          </div>
        </section>
      )}

      <Dialog.Root open={showDialog}>
        <ModalWrapper
          show={showDialog}
          className="!h-[200px] max-h-[80vh] w-[56%] max-w-[90vw]"
          ariaLabelledby="hFinText"
        >
          <div id="financialAid">
            <div className="mb-6 flex justify-between">
              <h2 className="text-[30px]" id="hFinText">
                {text}
              </h2>
              <Dialog.Close
                className="border-none text-3xl"
                onClick={(e) => {
                  close(e);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") close(e);
                }}
                aria-label="Close"
              >
                &times;
              </Dialog.Close>
            </div>
            <Paragraph>
              When you are finished, you can continue your session by returning
              to this tab.
            </Paragraph>
            <div
              className="text-right"
              style={{
                marginTop: "1.875rem",
                paddingTop: "1.875rem",
                borderTop: "0.063rem solid rgba( 233, 233, 233, 1 )",
              }}
            >
              <button
                style={{
                  backgroundColor: "#fff",
                  border: "solid rgba(253,191,56,1) 0.063rem",
                  color: "#000",
                  padding: "0.625rem 1.25rem",
                  fontWeight: "700",
                  fontSize: "0.9375rem",
                }}
                onClick={close}
                onKeyDown={(e) => {
                  if (e.key === "Enter") close(e);
                }}
              >
                Cancel
              </button>
              &nbsp;&nbsp;
              {urlHostName === urlQAT && (
                <button
                  style={{
                    backgroundColor: "rgba(253,191,56,1)",
                    border: "solid rgba(253,191,56,1) 0.063rem",
                    color: "#000",
                    padding: "0.625rem 1.25rem",
                    fontWeight: "700",
                    fontSize: "0.9375rem",
                  }}
                  id="okBtnFA"
                  onClick={() => OK(null, portalQAT)}
                >
                  Ok
                </button>
              )}
              {urlHostName === urlSTG && (
                <button
                  style={{
                    backgroundColor: "rgba(253,191,56,1)",
                    border: "solid rgba(253,191,56,1) 0.063rem",
                    color: "#000",
                    padding: "0.625rem 1.25rem",
                    fontWeight: "700",
                    fontSize: "0.9375rem",
                  }}
                  id="okBtnFA"
                  onClick={() => OK(null, portalSTG)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") OK(e, portalSTG);
                  }}
                >
                  Ok
                </button>
              )}
              {urlHostName === urlPROD && (
                <button
                  style={{
                    backgroundColor: "rgba(253,191,56,1)",
                    border: "solid rgba(253,191,56,1) 0.063rem",
                    color: "#000",
                    padding: "0.625rem 1.25rem",
                    fontWeight: "700",
                    fontSize: "0.9375rem",
                  }}
                  id="okBtnFA"
                  onClick={() => OK(null, portalPROD)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") OK(e, portalPROD);
                  }}
                >
                  Ok
                </button>
              )}
            </div>
          </div>
        </ModalWrapper>
      </Dialog.Root>
    </div>
  );
};

angularize(FinancialAid, "financialAidReact", angular.module("duApp"), {
  title: "<",
});
export default FinancialAid;
